import { createSlice } from "@reduxjs/toolkit";

export interface IToken {
    token: string,
    expires_at: string| null,
}


export const initialStateValue: IToken = {token: '', expires_at: null}; 

export const tokenSlice = createSlice({
    name: "token",
    initialState: { value: initialStateValue },
    reducers: {
        changeToken: (state, action) => {
            state.value = action.payload;
        },
        resetToken: (state) => {
            state.value = {token: '', expires_at: null};
        },
    },
});

export const { changeToken, resetToken} = tokenSlice.actions;

export default tokenSlice.reducer;
