import { createContext, useContext, useMemo, useState } from "react";

export const ShoppingCartContext = createContext<IShoppingCart>({num:0, setNum: ()=> {}});
export const useShoppingCarts = () => useContext(ShoppingCartContext);

export interface IShoppingCart {
    num: number,
    setNum: Function,
}

const ShoppingCartProvider = ({children}:{children: React.ReactElement}) =>{
    const [num, setNum] = useState(0);

    const value = useMemo((): IShoppingCart =>({
        num,
        setNum,

    }),[num]);

    return <ShoppingCartContext.Provider value={value}>{children}</ShoppingCartContext.Provider>
}
export default ShoppingCartProvider;