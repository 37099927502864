import { useSelector } from "react-redux";
import { RootState } from '../../store';

import { IToken } from '../token';

/**
 * Get token string
 * 
 * @returns string
 */
const useStateToken = () => useSelector<RootState, IToken>((state: RootState): IToken => state.token.value);

export default useStateToken;
