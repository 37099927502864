import { FC, useEffect, useState } from "react";
import useStateToken from "@react-redux/hooks/useStateToken";

const TestRefreshToken: FC = () => {

    const token = useStateToken();

    const [strToken, setStrToken] = useState<string>('');
    const [expires_at, setExpires_at] = useState<string>('');

    useEffect(()=>{
        console.log('Test rerender!!!');
    }, [])

    useEffect(() => {
        console.log('token changed!!!');
        setStrToken(token.token);
        setExpires_at(token.expires_at as string);
    }, [token]);

    return (
        <>
        {console.log('We rerender this page!!!')}
        <p>Token: {strToken ? strToken: 'empty'}</p>
        <br />
        <p>expires_at: {expires_at ? expires_at: 'empty'}</p>
        </>
    )
}

export default TestRefreshToken;