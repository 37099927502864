import { useShoppingCarts } from "./providers/ShoppingCartProvider";

const CarNum = () =>{
    const { num } = useShoppingCarts();

    return (
        <>
        <p>
            Cart number: {num}
        </p>
        </>
    )
}
export default CarNum;