import {
    createContext,
    useContext,
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
} from "react";
import { IUserState, initialUserStateValue } from "../../features/user";
import { IToken } from "../../features/token";
import useAxiosAnywhere from "../../hooks/useAxiosAnywhere";
import useStateToken from "@react-redux/hooks/useStateToken";
import { isTokenExpired } from "../../utils/utils";

interface ILoginUser {
    loginUser: IUserState;
    setLoginUser: Function;
    isAccessTokenExpired: Function;
}

export const LoginUserContext = createContext<ILoginUser>({
    loginUser: initialUserStateValue,
    setLoginUser: () => {},
    isAccessTokenExpired: () => {},
});
export const useLoginUser = () => useContext(LoginUserContext);

// Redux does not render components, we use this provider to re-render (refresh)
const LoginUserProvider = ({ children }: { children: React.ReactNode }) => {
    const [loginUser, setLoginUser] = useState<IUserState>(
        initialUserStateValue
    );
    const { asyncRequestState, axiosRequest } = useAxiosAnywhere();
    const token = useStateToken();
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        if (asyncRequestState.data) {
            console.log("data in LoginUserProvider!", asyncRequestState.data);
            setLoginUser(asyncRequestState.data as IUserState);
        }
    }, [asyncRequestState]);

    useEffect(() => {
        if (isMounted.current) return;

        const request = {
            method: "post",
            url: "/api/v1/user/refresh/",
            data: {
                refresh: token.token,
            },
        };
        (async () => {
            if (!isTokenExpired(token.expires_at as string))
                await axiosRequest(request);
        })();
        isMounted.current = true;
    }, [token, axiosRequest]);

    const isAccessTokenExpired = useCallback(() => {
        return isTokenExpired(loginUser.access_expires_at);
    }, [loginUser]);

    const value = useMemo(
        (): ILoginUser => ({
            loginUser,
            setLoginUser,
            isAccessTokenExpired,
        }),
        [loginUser, isAccessTokenExpired]
    );

    return (
        <LoginUserContext.Provider value={value}>
            {asyncRequestState.isLoading && <h1>Refresh user info</h1>}
            {children}
        </LoginUserContext.Provider>
    );
};
export default LoginUserProvider;
