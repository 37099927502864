import { useState, useMemo, useEffect, lazy } from "react";
import { useTranslation } from "react-i18next";
import { componentPermissions } from '../utils/constants';
import { BASE_PATH } from "../env.dev";

interface IMenuItem {
    path: string;
    title: string;
};

const useMenuList = () => {
    const { t } = useTranslation("useMenuList");

    return useMemo<Array<IMenuItem>>(
        () => [
            { path: "/", title: t("home", "Home") },
            {
                path: `${BASE_PATH}/about`,
                title: t("aboutUs", "About Us"),
            }, 
            {
                path: "/add_new_cart_num",
                title: "Add New Cart Number",
            }, 
            { path: "/register", title: "Register" }, 
            
            { path: "/login", title: "Login" }, 
            {path: "/contact", title: "Contact Us"}, 
            {path: "/contact_with_captcha", title: "Contact Us"}, 
            
            { path: "/logout", title: "Logout JWT" }, 

            {
                path: "/event_calendar",
                title: "Event Calendar",
            }, 
            {
                path: "/event_calendar_list",
                title: "Event Calendar List",
            }, 
            {
                path: "/activate_user_through_email",
                title: "Activate User Through Email",
            }, 
            { path: "/yup_demo", title: "Yup Demo" }, 
            {
                path: "/search_filters",
                title: "Search Filters",
            }, 
            {
                path: "/mui_component_01",
                title: "MUI Component 01",
            }, 
            {
                path: "/mui_font_color",
                title: "MUI Font Color",
            }, 
            {
                path: "/captcha",
                title: "Captcha Demo",
            }, 
            {
                path: "/pdfviewer/",
                title: "PDF Viewer",
            }, 
            {
                path: "/storeflyerviewer/",
                title: 'Store Flyer Viewer',
            },
            {
                path: "/scrolldemo",
                title: "Scroll Demo",
            }, 
            {
                path: "/springlightboxdemo",
                title: "Spring Lightbox Demo",
            }, 
            {
                path: "/photoswipedemo",
                title: "photo Swipe Demo",
            }, 
            {
                path: "/muiswiipe",
                title: "MUIMultiCarouselWithPhotoSwipeGallery",
            }, 
            {
                path: "/pdfembed",
                title: "PDF embed Viewer",
            }, 
            { path: "/printit", title: "Print it" }, 
            { path: "/i18ndemo", title: "i18n Demo" }, 
        ],
        [t]
    );
};

export default useMenuList;
