import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { BASE_PATH } from './env.dev';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

//check site host using window.location.host
const hosts =['linglingtest.com:5188', 'localhost:3000'];

console.log('in i18n.ts', hosts.includes(window.location.host));

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: hosts.includes(window.location.host), //true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'htmlTag', 'sessionStorage', 'navigator', 'path', 'subdomain'],
        caches: ['localStorage'],
    },
    backend: {
        loadPath: `${BASE_PATH}/locales/{{lng}}/{{ns}}.json`, //in the folder public (e.g. public/locales/en/app.json) 
        // loadPath: 'https://linglingli.ca/locales/{{lng}}/{{ns}}.json',  //Json files in the server
        // loadPath: 'https://api.thanhphat.ca/api/v1/locales/{{lng}}/{{ns}}.json', // This is a json data API or download json file API
        
        //https://stackoverflow.com/questions/70125682/cache-issue-with-localization-files-react-i18next
        requestOptions: {
            cache: 'no-store',
        },
    },
    /*
    // We comment out the following setting object so we have to use <Suspense fallback="loading"> in index.js
    // if we use the following react object, we do not need to use <Suspense fallback="loading"> in index.js
    react: {
        useSuspense: false, 
    }, */
  });


export default i18n;