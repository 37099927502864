import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useAxiosAnywhere from "../hooks/useAxiosAnywhere";
import useStateToken from "@react-redux/hooks/useStateToken";
import { useDispatch } from "react-redux";
import { login, initialUserStateValue, IUserState } from "@react-redux/reducers/user";
import { useLoginUser } from "@components/providers/LoginUserProvider";
import { useEffect, useCallback } from "react";

const useRefreshToken = (isNavigateNeeded: boolean = false) => {
    const token = useStateToken();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location is', location);
    const { asyncRequestState, axiosRequest, setAsyncRequestInitialState } = useAxiosAnywhere();

    const { setLoginUser } = useLoginUser();

    useEffect(()=>{
        if(asyncRequestState.error){
            setLoginUser(initialUserStateValue);
            isNavigateNeeded && navigate("/login", {
                state: { from: location },
                replace: true,
            });
            return;
        }

        if(asyncRequestState.data){
            setLoginUser(asyncRequestState.data as IUserState);
        }

    }, [asyncRequestState, isNavigateNeeded, location, navigate, setLoginUser])

    return useCallback(async () => {
        console.log("call refresh token");
        
        const request = {
            method: "post",
            url: "/api/v1/user/refresh/",
            data: { refresh: token.token }, // Note: this is refresh token
        };
        const data = await axiosRequest(request);
        const access = (data as IUserState)?.access ?? '';
        console.log('access in useRefreshToken', access);
        return access;
    }, [axiosRequest, token] );
};

export default useRefreshToken;
