import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { useDispatch } from "react-redux";
import {login} from '@react-redux/reducers/user'
import { changeToken } from "@react-redux/reducers/token";
import useStateToken from '@react-redux/hooks/useStateToken';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Navigate } from "react-router-dom";

import {logout} from '@react-redux/reducers/user'
import { resetToken } from "@react-redux/reducers/token";
import { useNavigate } from "react-router-dom";

//api/v1/user/extend_login/

const RenewRefreshTokenModal = (): React.ReactElement => {
    const { t } = useTranslation("renew_refresh_token_modal");
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [showModal, setShowModal] = useState(false);
    const [timerInfo, setTimerInfo] = useState<string>('Wait...');
    const [displayThisComponent, setDisplayThisComponent] = useState(false);       
    
    const handleClose = () => setShowModal(false);

    const handleShow = () => setShowModal(true);
    

    const token = useStateToken();

    // useEffect(()=>{
    //     if(!token.expires_at) {
    //         setShowModal(false);
    //         setDisplayThisComponent(false)
    //     }   
    //   }, [token]);

    const handleLogout = ()=>{ // This methond should call logout API
        setShowModal(false);
        setDisplayThisComponent(false);
        setTimerInfo('Timeout');
        dispatch(resetToken());

            dispatch(
                logout()
            );
            navigate('/login');

    }

    const handleRefresh  = async ()=> {
        setShowModal(false);
        setDisplayThisComponent(false);
        const request = {
            method: 'get',
            url: '/api/v1/user/extend_login/',
        };
        try{
        //     const {data} = await axiosPrivate(request);
        //     console.log("data", data);

        // dispatch(changeToken({
        //     token: data.refresh,
        //     expires_at: data.expires_at,
        // }));

        // dispatch(
        //     login({
        //         email: data.email,
        //         access: data.access,
        //         permissions: data.permissions ?? [],
        //     })
        // );
        
    } catch(error: any) {
        console.log(error);
        console.log('error?.response?.status', error?.response?.status)

        if(error?.response?.status === 401){
            dispatch(
                login({
                    email: "",
                    access: "",
                    Permissions: []
                })
            );
        }
        setShowModal(false);
        setDisplayThisComponent(false);
        return <Navigate to="/login" />;

        //TODO: popup messages for error?.response?.status===403
    }
};

    useEffect(()=>{
        if(!token.expires_at) {
            setShowModal(false);
            setDisplayThisComponent(false)
            return;
        }   

        // const intervalId = setInterval(()=>{
            
        //     const current = moment.tz(`${defaultTimezone}`);
        //     const expires_at = moment(token.expires_at).tz(`${defaultTimezone}`);
        //     // console.log('token.expires_at', token.expires_at);
        //     // console.log('expires_at', expires_at);
        //     // console.log("expires_at.diff(current, 'seconds')", expires_at.diff(current, 'seconds'));

        //     const duration = expires_at.diff(current, 'seconds');
        //     // console.log('duration', duration);
        //     if(duration <= 0){
        //         setShowModal(false);
        //         setDisplayThisComponent(false);
        //         clearInterval(intervalId);
        //     }

        //     if(duration <= 240 && duration > 0) {
        //         setShowModal(true);
        //         setDisplayThisComponent(true)
        //         clearInterval(intervalId);
                
        //     }else{
        //         setShowModal(false);
        //         setDisplayThisComponent(false);
        //     }
        // }, 1000);
        // return () => clearInterval(intervalId);
    }, [token, defaultTimezone]);

    useEffect(()=>{

        // const intervalId = setInterval(()=>{
        //     const current = moment.tz(`${defaultTimezone}`);
        //     const expires_at = moment(token.expires_at).tz(`${defaultTimezone}`);
        //     const duration = expires_at.diff(current, 'seconds')

        //     duration>0 && setTimerInfo(`${moment.duration({'seconds' : duration}).minutes()} : ${moment.duration({'seconds' : duration}).seconds()<10 ? "0"+moment.duration({'seconds' : duration}).seconds(): moment.duration({'seconds' : duration}).seconds() }`);
        //     duration===0 && handleLogout();
        // }, 1000);
        // return () => clearInterval(intervalId);
    }, [token, defaultTimezone, handleLogout]);

    
    return (
        <>
        {displayThisComponent && 
        <>
        <Button variant="primary" onClick={handleShow}>
            Extend login
        </Button>
    
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>{timerInfo}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleRefresh}>
             Extend Login
            </Button>
            </Modal.Footer>
        </Modal>
        </>}
        </>
    );
      
};

export default RenewRefreshTokenModal;
