import { ReactNode } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

type ChildrenProps = {
    children: ReactNode;
    
}

const MuiThemeProvider = ({ children }: ChildrenProps ) => {
    const theme = createTheme({
        spacing: (factor: number) => `${0.25 * factor}rem`, // (Bootstrap strategy)
      });

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        </ThemeProvider>
    );
};

export default MuiThemeProvider;
