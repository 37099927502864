import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
import MuiThemeProvider from "./components/providers/MuiThemeProvider";
import "bootstrap/dist/css/bootstrap.min.css";

import { Provider } from "react-redux";
import LoginUserProvider from "@components/providers/LoginUserProvider";
import store, { persistor } from "./store";
import { RotatingLines } from "react-loader-spinner";
import { Elements } from '@stripe/react-stripe-js';
import { HelmetProvider } from "react-helmet-async";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Suspense
        fallback={
            <div className="centered_icon">
                <RotatingLines
                    strokeColor="red"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
                <div>Loading ...</div>
            </div>
        }
    >
        <React.StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <MuiThemeProvider>
                        <LoginUserProvider>
                            <BrowserRouter>
                                <HelmetProvider>
                                    <App />
                                </HelmetProvider>
                            </BrowserRouter>
                        </LoginUserProvider>
                    </MuiThemeProvider>
                </PersistGate>
            </Provider>
        </React.StrictMode>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
