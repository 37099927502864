import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../api/axios";

export interface IUserState {
    email: string,
    access: string,
    permissions: string[],
    access_expires_at: string,
}

export const initialUserStateValue: IUserState = { email: "", access: "", permissions: [], access_expires_at: "" };


export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialUserStateValue },
  reducers: {
    // login: (state, action) => {
    //   state.value = action.payload;
    // },

    // login: (state, action) => ({
    //     ...state,
    //     value: action.payload,
    //   }),

    login: (state, action) => {
        state.value = action.payload;
    },

    logout: (state) => {
        state.value = initialUserStateValue;
    },
  },
});

export const { login, logout} = userSlice.actions;

export default userSlice.reducer;