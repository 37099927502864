// export const BASE_URL = 'https://linglingli.ca';
export const BASE_URL = 'https://api.thanhphat.ca';

export const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
// export const defaultTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const defaultTimeFormat = "ddd, MMMM Do, YYYY hh:mm:ssa";

export const permissions = [
    "token_blacklist.view_blacklistedtoken",
    "rest_cors.view_origin",
    "auth.add_group",
    "rest_cors.change_origin",
    "event_calendar.view_eventcalendar",
    "account.view_emailconfirmation",
    "token_blacklist.delete_blacklistedtoken",
    "authtoken.add_tokenproxy",
    "account.add_emailconfirmation",
    "users.delete_customuser",
    "react_translations.delete_i18key",
    "auth.view_permission",
    "rest_cors.delete_origin",
    "admin.change_logentry",
    "admin.delete_logentry",
    "event_calendar.delete_eventcalendar",
    "react_translations.change_translation",
    "authtoken.change_tokenproxy",
    "sites.delete_site",
    "auth.delete_permission",
    "react_translations.delete_namespace",
    "account.change_emailconfirmation",
    "token_blacklist.delete_outstandingtoken",
    "authtoken.view_tokenproxy",
    "event_calendar.change_eventcalendar",
    "token_blacklist.view_outstandingtoken",
    "react_translations.change_language",
    "token_blacklist.add_blacklistedtoken",
    "sites.change_site",
    "react_translations.change_namespace",
    "users.add_customuser",
    "react_translations.view_language",
    "contenttypes.delete_contenttype",
    "react_translations.view_i18key",
    "authtoken.view_token",
    "sessions.add_session",
    "sites.view_site",
    "admin.view_logentry",
    "user_accounts.add_userstatus",
    "sessions.change_session",
    "sites.add_site",
    "auth.view_group",
    "user_accounts.change_userstatus",
    "user_accounts.delete_userstatus",
    "token_blacklist.change_blacklistedtoken",
    "auth.add_permission",
    "react_translations.add_translation",
    "user_accounts.delete_userprofile",
    "admin.add_logentry",
    "account.delete_emailconfirmation",
    "authtoken.add_token",
    "authtoken.delete_tokenproxy",
    "react_translations.add_namespace",
    "contenttypes.change_contenttype",
    "auth.change_group",
    "user_accounts.view_userstatus",
    "sessions.delete_session",
    "react_translations.add_language",
    "react_translations.delete_language",
    "react_translations.change_i18key",
    "account.view_emailaddress",
    "contenttypes.add_contenttype",
    "react_translations.view_namespace",
    "users.change_customuser",
    "authtoken.change_token",
    "auth.delete_group",
    "token_blacklist.add_outstandingtoken",
    "contenttypes.view_contenttype",
    "rest_cors.add_origin",
    "sessions.view_session",
    "react_translations.view_translation",
    "user_accounts.change_userprofile",
    "account.change_emailaddress",
    "event_calendar.add_eventcalendar",
    "account.delete_emailaddress",
    "react_translations.add_i18key",
    "authtoken.delete_token",
    "react_translations.delete_translation",
    "users.view_customuser",
    "token_blacklist.change_outstandingtoken",
    "auth.change_permission",
    "user_accounts.add_userprofile",
    "account.add_emailaddress",
    "user_accounts.view_userprofile"
];

export const componentPermissions = {
    event_calendar:[
        "event_calendar.add_eventcalendar",
        "event_calendar.view_eventcalendar",
    ],
    event_calendar_list:[
        "event_calendar.view_eventcalendar",
    ],
    change_password:[],


}