import moment from "moment-timezone";
import { defaultTimezone, defaultTimeFormat } from "./constants";

export const isTokenExpired = (token_expired_time: string): boolean => {
    if (!token_expired_time) return true;

    console.log("token_expired_time", token_expired_time);

    const current = moment.tz(`${defaultTimezone}`);
    const expires_at = moment(token_expired_time).tz(`${defaultTimezone}`);

    console.log("expires_at", expires_at.format());

    console.log("expires_at.diff", expires_at.diff(current, "seconds"));
    console.log('is expired', expires_at.diff(current, "seconds") <= 0);

    return expires_at.diff(current, "seconds") <= 0;
};

export const getLocalTime = (utcTime: string, localTimeZone: string|null  = null, dateTimeFormat: string|null  = null) => {
    const timeZone = localTimeZone ? localTimeZone : defaultTimezone;
    const outputDateTimeFormat = dateTimeFormat? dateTimeFormat : defaultTimeFormat;
    return moment(utcTime).tz(timeZone).format(outputDateTimeFormat);
}

export const getLocalTimeFromNow = (utcTime: string, localTimeZone: string|null  = null, dateTimeFormat: string|null  = null) => {
    const timeZone = localTimeZone ? localTimeZone : defaultTimezone;
    const outputDateTimeFormat = dateTimeFormat? dateTimeFormat : defaultTimeFormat;
    return moment(utcTime).tz(timeZone).fromNow();
}

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = (componentImport: ()=>Promise<{
    default: React.ComponentType<any>;
}>, name: string): Promise<{
    default: React.ComponentType<any>;
}> =>{
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );

        console.log('typeof hasRefreshed:', typeof hasRefreshed);
        // try to import the component
        componentImport().then((component:
            {default: React.ComponentType<any>;
        }) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error:any) => {
            if (!hasRefreshed) { // not been refreshed yet
                console.log('typeof hasRefreshed:', typeof hasRefreshed);
                console.log('hasRefreshed:', hasRefreshed);
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};
