// https://thewebdev.info/2022/03/13/how-to-show-loading-when-making-ajax-request-with-axios-in-vue-js/

import { AxiosRequestConfig } from "axios";
import { useEffect, useMemo, useState, useCallback } from "react";
import axios, {AxiosInstance} from 'axios';
import { BASE_URL } from '../utils/constants';
import { initialAsyncRequestState, useAsyncRequestState } from "./useAsyncRequestState";

const useAxiosAnywhere = (throwError: boolean = false) => {
    const axiosInstance  =  useMemo<AxiosInstance>(()=> axios.create({
        baseURL: BASE_URL
    }), []);

    const [asyncRequestState, updateAsyncRequestState, setAsyncRequestInitialState] = useAsyncRequestState();

    useEffect(() => {
        const requestIntercept = axiosInstance.interceptors.request.use(
            (config) => {
                updateAsyncRequestState({ ...initialAsyncRequestState, isLoading: true });
                console.log('config in requestIntercept', config);
                return config;
            },
            (error) => {
                updateAsyncRequestState({ ...initialAsyncRequestState, error });
                return Promise.reject(error);
            }
        );

        const responseIntercept = axiosInstance.interceptors.response.use(
            (response) => {
                console.log('response in useAxiosAnywhere', response);
                updateAsyncRequestState({ ...initialAsyncRequestState, data: response.data, responseHeaders: response.headers });
                return response;
            },
            (error) => {
                updateAsyncRequestState({ ...initialAsyncRequestState, error });
                console.log(
                    "error in axiosInstance.interceptors.response of useAxioAnywhere",
                    error
                );
                if(throwError) return Promise.reject(error);
            }
        );

        return () => {
            axiosInstance.interceptors.request.eject(requestIntercept);
            axiosInstance.interceptors.response.eject(responseIntercept);
        };
    }, [axiosInstance, throwError, updateAsyncRequestState]);

    // return axiosInstancePrivate; //This is the old version, I use the following return version:

    // return (request: AxiosRequestConfig<any>) =>{
    //     return (async () => {
    //         try{
    //             return await axiosInstancePrivate(request);
    //         }catch (err) {
    //             console.error(err);
    //         }
    //     })();
    // }

    return {
        asyncRequestState,
        axiosRequest: useCallback(async (request: AxiosRequestConfig<any>) => ((await axiosInstance(request)) as any)?.data, [axiosInstance]),
        setAsyncRequestInitialState,
    };
};

export default useAxiosAnywhere;
