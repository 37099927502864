import axios, {AxiosInstance} from 'axios';
import { BASE_URL } from '../utils/constants';
import { initialAsyncRequestState, useAsyncRequestState } from "./useAsyncRequestState";
import {AxiosRequestConfig} from 'axios';
import { useEffect, useMemo, useCallback } from "react";
import useRefreshToken from "./useRefreshToken";
import { useDispatch } from "react-redux";
import { useLoginUser } from "@components/providers/LoginUserProvider";
import { axiosInstance } from "../api/axios";
import useStateToken from "@react-redux/hooks/useStateToken";

const useAxiosPrivate = (throwError: boolean = false) => {
    const refresh = useRefreshToken(true);
    const { loginUser, isAccessTokenExpired } = useLoginUser();
    const dispatch = useDispatch();
    const token = useStateToken();

    const axiosInstancePrivate  =  useMemo<AxiosInstance>(()=> axios.create({
        baseURL: BASE_URL
    }), []);

    const [asyncRequestState, updateAsyncRequestState, setAsyncRequestInitialState] = useAsyncRequestState();

    useEffect(() => {
        
        const requestIntercept = axiosInstancePrivate.interceptors.request.use(
            async (config) => {
                updateAsyncRequestState({ ...initialAsyncRequestState, isLoading: true });
                if (!config.headers?.['Authorization']) {
                    //in Typescript, exclamation mark operator(!, A new ! post-fix expression operator) is the non-null assertion operator
                    //It tells the compiler "this expression cannot be null or undefined here, so don't complain about the possibility of it being null or undefined." 
                    // config.headers!['Authorization'] = `Bearer ${loginUser.access}`;
                    const access_token = isAccessTokenExpired()? await refresh(): loginUser.access;
                    console.log('access_token in config of requestIntercept', access_token)
                    config.headers!['Authorization'] = `Bearer ${access_token}`;
                }
                console.log('config in useAxiosPrivate', config);
                return config;
            }, 
            (error) => {
                updateAsyncRequestState({ ...initialAsyncRequestState, error });
                return Promise.reject(error)
            },
        );

        const responseIntercept = axiosInstancePrivate.interceptors.response.use(
            (response)=> {
                updateAsyncRequestState({ ...initialAsyncRequestState, data: response.data, responseHeaders: response.headers });
                return response;
            },
            (error) => {
                const prevRequest = error?.config;
                console.log('error happens when sending request:', error);
                console.log('error?.response?.status', error?.response?.status);
                console.log('prevRequest?.sent', prevRequest?.sent);
                // if ((error?.response?.status === 401 && error?.response?.data?.code ==='token_not_valid') && !prevRequest?.sent) {
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    console.log("have to refresh token");
                    console.log('error?.response', error?.response);
                    prevRequest.sent = true;

                    // // const newAccessToken = await refresh();
                    // const newAccessToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzQ2Mjg5OTc3LCJpYXQiOjE2NzE1NTM5NzcsImp0aSI6IjRmNjQxOWNjYjY2NDRiZTBiNTU4YmE4M2UwYjZlY2JlIiwidXNlcl9pZCI6MX0.66UYT0qlU6hOQjcGzsdDUFMf-7cBA_XK3fVAC3w0bfY';

                    // console.log('newAccessToken in error', newAccessToken);

                    // prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosInstancePrivate(prevRequest);
                }
                updateAsyncRequestState({ ...initialAsyncRequestState, error });
                console.log('error in axiosInstancePrivate.interceptors.response of useAxiosPrivate', error);
                if(throwError) return Promise.reject(error);
            }
        );

        return () => {
            axiosInstancePrivate.interceptors.request.eject(requestIntercept);
            axiosInstancePrivate.interceptors.response.eject(responseIntercept);
        }
    }, [isAccessTokenExpired, axiosInstancePrivate, refresh, dispatch, loginUser, updateAsyncRequestState, throwError]);

    // return axiosInstancePrivate; //This is the old version, I use the following return version:

    // return (request: AxiosRequestConfig<any>) =>{
    //     return (async () => {
    //         try{
    //             return await axiosInstancePrivate(request);
    //         }catch (err) {
    //             console.error(err);
    //         }
    //     })();
    // }

    return {
        asyncRequestState,
        axiosPrivate: useCallback(
            (request: AxiosRequestConfig<any>) => axiosInstancePrivate(request),
            [axiosInstancePrivate]
        ),
    };
}

export default useAxiosPrivate;