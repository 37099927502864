import { useState, FC, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import CloseIcon from "@mui/icons-material/Close";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import useMenuList from "../hooks/useMenuList";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import LinkText from "@components/LinkText";

interface IActiveStyle {
    textDecoration: string;
    color?: string;
}

const activeStyle: IActiveStyle = {
    textDecoration: "underline",
};

const noneActiveStyle: IActiveStyle = {
    textDecoration: "none",
    color: "red",
};

const MuiHamburgerMenu: FC = () => {
    const location = useLocation();
    const menuList = useMenuList();
    const [drwOpen, setDrwOpen] = useState(false);
    const [, updateState] = useState<object>({});

    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            console.log("open in toggerDrawer", open);
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setDrwOpen(open);
        };

    useEffect(() => {
        updateState({});
    }, [drwOpen]);

    const list = () => (
        <Box
            sx={{ width: "16rem" }} //sx={{ width: "auto" }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <button onClick={toggleDrawer(false)}>close</button>

            <MenuList>
                {menuList.map((menuItem, index) => (
                    <MenuItem
                        selected={location.pathname === menuItem.path}
                        key={menuItem.path}
                    >
                        <ListItemIcon>
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                        <Tooltip title={menuItem.title +
                                    ": " +
                                    drwOpen +
                                    "; this is really long text"} placement="top-end">
                            <NavLink
                                to={menuItem.path}
                                style={({ isActive }) =>
                                    isActive ? noneActiveStyle : activeStyle
                                }
                            >
                                {menuItem.title +
                                    ": " +
                                    drwOpen +
                                    "; this is really long text"}
                            </NavLink>
                            </Tooltip>
                        </Typography>
                    </MenuItem>
                ))}
            </MenuList>
            <Divider />
            <List>
                {["All mail", "Trash", "Spam"].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    return (
        <>
            <MenuIcon onClick={toggleDrawer(true)} />
            <Drawer anchor="left" open={drwOpen} onClose={toggleDrawer(false)}>
            {list()}
            </Drawer>
        </>
    );
};

export default MuiHamburgerMenu;
