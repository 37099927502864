import { useReducer } from "react";

export interface IAsyncRequestState {
    data: object|null,
    isLoading: boolean,
    error: any|null,
    responseHeaders: any|null,
};

export const initialAsyncRequestState: IAsyncRequestState = {
    data: null,
    isLoading: false,
    error: null,
    responseHeaders: null,
};

export const useAsyncRequestState = () =>{

    const [asyncRequestState, updateAsyncRequestState] = useReducer(
        (previousAsyncRequestState: IAsyncRequestState, newAsyncRequestState: IAsyncRequestState ) => ({ ...previousAsyncRequestState, ...newAsyncRequestState }),
        initialAsyncRequestState,
    );

    const setAsyncRequestInitialState = () => {
        updateAsyncRequestState(initialAsyncRequestState);
    }

    return [asyncRequestState, updateAsyncRequestState, setAsyncRequestInitialState] as const;
}