import { useCallback } from 'react';
import { useLoginUser } from "@components/providers/LoginUserProvider";

const useHasPermissions = (): Function => {
    const { loginUser } = useLoginUser();
    
    return useCallback((requiredPermissions: string[]) =>{

        if (!loginUser.access && !loginUser.email){
            return false;
        }

        if (!loginUser.permissions || loginUser.permissions.length===0 ) return false;

        return requiredPermissions?.every(item => loginUser.permissions?.includes(item));

    }, [loginUser]);

};

export default useHasPermissions;