import { FC, lazy, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useRoutes, NavLink } from "react-router-dom";

import useComponents from './hooks/useComponents'
import LanguageSelector from '@components/LanguageSelector';
import RenewRefreshTokenModal from '@components/RenewRefreshTokenModal';

import ShoppingCartProvider from './components/providers/ShoppingCartProvider';
import CartNum from './components/CartNum';
import MuiHamburgerMenu  from './components/MuiHamburgerMenu';
import { SpinnerComponent } from 'react-element-spinner';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useLoginUser } from "@components/providers/LoginUserProvider";
import TestRefreshToken from './TestRefreshToken';
import useHasPermissions from "@react-redux/hooks/useHasPermissions";
import { componentPermissions } from './utils/constants';
import { Helmet } from "react-helmet-async";
import printJS from 'print-js'
import { BASE_PATH } from './env.dev';


const DisplayUserEmail = lazy(() => import('./components/userManagement/DisplayUserEmail'));


interface IActiveStyle {
    textDecoration: string
    color?: string
}

const App: FC = () => {
  const { t } = useTranslation("app");


  const { loginUser } = useLoginUser();

  const routeListData = useComponents();
  let activeStyle: IActiveStyle = {
    textDecoration: "underline",
  };
  const hasPermissions = useHasPermissions();

  let noneActiveStyle: IActiveStyle = {
    textDecoration: "none",
    color: 'red',
  };

//   useEffect(() => { document.title = t('documentTitle', 'My demo web site') }, [t]);
const printContent = () => {
        
    printJS({printable: 'content_by_id', type: 'html', style: 'width: 100%;'});
}
  
  return (
    <>
    <Helmet>
        <title>{t('documentTitle', 'My demo web site - for testing')}</title>
    </Helmet>
    <ShoppingCartProvider>
    <Box sx={(theme) => (
        { 
            flexGrow: 1,
            px: 4,
            py: 3,
            fontFamily: 'Arial,sans-serif',
        }
    )}>
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
                <LanguageSelector />
                <DisplayUserEmail />
            
            <header>
                <h1>
                    {t('hello', 'How are you ')} <CartNum />
                </h1>
                <SpinnerComponent loading={false} position="global" message={t('loading','Loading')} />
                <p>
                user expire: {loginUser?.access?.substring(loginUser?.access?.length-5, loginUser?.access?.length-1)}
                </p>
                <MuiHamburgerMenu />
                <RenewRefreshTokenModal />
                <p>
                <NavLink
                    to={`${BASE_PATH}/about`}
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    About
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    home
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/add_new_cart_num"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    Add New Cart Number
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/storeflyerviewer"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    Store Flyer
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/userinfo"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                User Info
                </NavLink>
                </p>
                {hasPermissions(componentPermissions['event_calendar']??[]) && <p>
                <NavLink
                    to="/event_calendar"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                Event Calendar
                </NavLink>
                </p>}
                {loginUser.email && <p>
                <NavLink
                    to="/change_password"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                Change Password
                </NavLink>
                </p>}
                <p>
                <NavLink
                    to="/login"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                Login
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/logout"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                Logout
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/register"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                Register
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/yup_demo"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                Yup Demo
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/search_filters"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    Search Filters
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/mui_component_01"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                    state={{ from: 'from value'}}
                >
                    MUI Component 01
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/mui_font_color"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    MUI Font Color
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/pdfviewer"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    PDF Viewer
                </NavLink>
                </p>
                <p>
                <NavLink
                    to="/muiswiipe"
                    style={({ isActive }) =>
                    isActive ? noneActiveStyle : activeStyle 
                    }
                >
                    Mui Swippe
                </NavLink>
                </p>
            </header>
        </Grid>
        <Grid item xs={12} sm={6} md={9}>
            <TestRefreshToken />
            <hr />
            <hr />
            <div id="content_by_id">
                {useRoutes(routeListData)}
            </div>
            <br />
            <button onClick={printContent}>print content in App</button>
        </Grid>
      </Grid>
    </Box>
    </ShoppingCartProvider>
    </>
  );
}

export default App;
