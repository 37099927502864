import { useState, useMemo, useEffect, lazy } from "react";
import { useTranslation } from "react-i18next";
import { componentPermissions } from "../utils/constants";
import { lazyRetry } from "../utils/utils";
import { BASE_PATH } from "../env.dev";
/*
import Home from '@components/Home';
import About from '@components/About';
import Register from '@components/Register';
import ActivateRegisterAccount from '@components/ActivateRegisterAccount';
import EventCalendar from '@components/EventCalendar';
import W404 from '@components/W404';
import Login from '@user-management/Login';
import ChangePassword from '@user-management/ChangePassword';
import LogoutJWT from '@user-management/LogoutJWT';
import UserInfo from '@components/userManagement/UserInfo';
import ActivateUserThroughEmail from '@components/userManagement/ActivateUserThroughEmail';
import AddNewCartNum from '@components/AddNewCartNum';
import YupDemo from '../components/YupDemo';
import ProtectedRoute from '../components/ProtectedRoute';
import SearchFilters from '../search/SearchFilters';
import MUIComponent01 from '../muiExamples/MUIComponent01';
import MUIFontColorComponent from '../muiExamples/MUIFontColorComponent';
*/

/*
React.lazy currently only supports default exports.
If the module you want to import uses named exports,
you can create an intermediate module that reexports it as the default.
This ensures that tree shaking keeps working and that you don’t pull in
unused components.
*/
const Home = lazy(() => lazyRetry(() => import("@components/Home"), "home"));
const About = lazy(() => lazyRetry(() => import("@components/About"), "about"));
const Register = lazy(() =>
    lazyRetry(() => import("@components/Register"), "register")
);
const ActivateRegisterAccount = lazy(() =>
    lazyRetry(
        () => import("@components/ActivateRegisterAccount"),
        "actActivateRegisterAccount"
    )
);
const EventCalendar = lazy(() =>
    lazyRetry(() => import("@components/EventCalendar"), "eventcalendar")
);
const EventCalendarListWithLocalTimeZone = lazy(() =>
    lazyRetry(
        () => import("@components/EventCalendarListWithLocalTimeZone"),
        "EventCalendarListWithLocalTimeZone"
    )
);
const StripeCheckoutForm = lazy(() =>
    lazyRetry(
        () => import("@components/StripeCheckoutForm"),
        "StripeCheckoutForm"
    )
);
const StripePaymentCancel = lazy(() =>
    lazyRetry(
        () => import("@components/StripePaymentCancel"),
        "StripePaymentCancel"
    )
);
const StripePaymentSuccess = lazy(() =>
    lazyRetry(
        () => import("@components/StripePaymentSuccess"),
        "StripePaymentSuccess"
    )
);
const StripeListAllPayments = lazy(() =>
    lazyRetry(
        () => import("@components/StripeListAllPayments"),
        "StripeListAllPayments"
    )
);

const W404 = lazy(() => lazyRetry(() => import("@components/W404"), "W404"));
const NoPermissionAllowed = lazy(() =>
    lazyRetry(
        () => import("@components/NoPermissionAllowed"),
        "NoPermissionAllowed"
    )
);
const Login = lazy(() =>
    lazyRetry(() => import("@user-management/Login"), "Login")
);
const ChangePassword = lazy(() =>
    lazyRetry(() => import("@user-management/ChangePassword"), "ChangePassword")
);
const LogoutJWT = lazy(() =>
    lazyRetry(() => import("@user-management/LogoutJWT"), "LogoutJWT")
);
const UserInfo = lazy(() =>
    lazyRetry(() => import("@components/userManagement/UserInfo"), "UserInfo")
);
const ActivateUserThroughEmail = lazy(() =>
    lazyRetry(
        () => import("@components/userManagement/ActivateUserThroughEmail"),
        "aute"
    )
);
const AddNewCartNum = lazy(() =>
    lazyRetry(() => import("@components/AddNewCartNum"), "AddNewCartNum")
);
const YupDemo = lazy(() =>
    lazyRetry(() => import("../components/YupDemo"), "YupDemo")
);
const ProtectedRoute = lazy(() =>
    lazyRetry(() => import("../components/ProtectedRoute"), "ProtectedRoute")
);
const SearchFilters = lazy(() =>
    lazyRetry(() => import("../search/SearchFilters"), "SearchFilters")
);
const MUIComponent01 = lazy(() =>
    lazyRetry(() => import("../muiExamples/MUIComponent01"), "MUIComponent01")
);
const MUIFontColorComponent = lazy(() =>
    lazyRetry(
        () => import("../muiExamples/MUIFontColorComponent"),
        "MUIFontColorComponent"
    )
);
const InputCAPTCHA = lazy(() =>
    lazyRetry(() => import("../components/InputCAPTCHA"), "InputCAPTCHA")
);
const PdfViewer = lazy(() =>
    lazyRetry(() => import("../components/PdfViewer"), "PdfViewer")
);
const StoreFlyerViewer = lazy(() =>
    lazyRetry(
        () => import("../components/StoreFlyerViewer"),
        "StoreFlyerViewer"
    )
);
const ScrollDemo = lazy(() =>
    lazyRetry(() => import("../components/ScrollDemo"), "ScrollDemo")
);
const SpringLightboxDemo = lazy(() =>
    lazyRetry(
        () => import("../components/SpringLightboxDemo"),
        "SpringLightboxDemo"
    )
);
const PhotoSwipeDemo = lazy(() =>
    lazyRetry(() => import("../components/PhotoSwipeDemo"), "PhotoSwipeDemo")
);
const MUIMultiCarouselWithPhotoSwipeGallery = lazy(() =>
    lazyRetry(
        () => import("../muiExamples/MUIMultiCarouselWithPhotoSwipeGallery"),
        "MUIMultiCarouselWithPhotoSwipeGallery"
    )
);
const PDFEmbed = lazy(() =>
    lazyRetry(() => import("../components/PDFEmbed"), "PDFEmbed")
);
const PrintIt = lazy(() =>
    lazyRetry(() => import("../react_to_print/PrintIt"), "PrintIt")
);
const Contact = lazy(() =>
    lazyRetry(() => import("@user-management/Contact"), "Contact")
);
const ContactWithCAPTCHA = lazy(() =>
    lazyRetry(
        () => import("@user-management/ContactWithCAPTCHA"),
        "ContactWithCAPTCHA"
    )
);

const PDFDownloadDemo = lazy(() =>
    lazyRetry(
        () => import("../components/pdfDemo/PDFDownloadDemo"),
        "PDFDownloadDemo"
    )
);

const I18nDemo = lazy(() =>
    lazyRetry(() => import("@user-management/I18nDemo"), "I18nDemo")
);

const useComponents = () => {
    const { t } = useTranslation("useComponents");

    return useMemo(
        () => [
            { path: "/", element: <Home />, title: t("home", "Home") }, //for useRoutes, we do not need 'title'
            {
                path: `${BASE_PATH}/about`,
                element: <About />,
                title: t("aboutUs", "About Us"),
            }, //for useRoutes, we do not need 'title'
            {
                path: "/add_new_cart_num",
                element: <AddNewCartNum />,
                title: "Add New Cart Number",
            }, //for useRoutes, we do not need 'title'
            { path: "/register", element: <Register />, title: "Register" }, //for useRoutes, we do not need 'title'
            {
                path: "/activate_register_account/:uid/:token",
                element: <ActivateRegisterAccount />,
                title: "Activate Register Account",
            }, //for useRoutes, we do not need 'title'
            { path: "/login", element: <Login />, title: "Login" }, //for useRoutes, we do not need 'title'
            { path: "/contact", element: <Contact />, title: "Contact Us" }, //for useRoutes, we do not need 'title'
            {
                path: "/contact_with_captcha",
                element: <ContactWithCAPTCHA />,
                title: "Contact Us",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/change_password",
                element: (
                    <ProtectedRoute
                        permissions={componentPermissions["change_password"]}
                    >
                        <ChangePassword />
                    </ProtectedRoute>
                ),
                title: "Change Password",
            }, //for useRoutes, we do not need 'title'
            { path: "/logout", element: <LogoutJWT />, title: "Logout JWT" }, //for useRoutes, we do not need 'title'
            // {path: "/logout", element: (<ProtectedRoute hasPermissions={true}><LogoutJWT /></ProtectedRoute>), title: "Logout JWT"}, //for useRoutes, we do not need 'title'
            { path: "/userinfo", element: <UserInfo />, title: "User Info" }, //for useRoutes, we do not need 'title'
            {
                path: "/event_calendar",
                element: (
                    <ProtectedRoute
                        permissions={componentPermissions["event_calendar"]}
                    >
                        <EventCalendar />
                    </ProtectedRoute>
                ),
                title: "Event Calendar",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/event_calendar_list",
                element: (
                    <ProtectedRoute
                        permissions={
                            componentPermissions["event_calendar_list"]
                        }
                    >
                        <EventCalendarListWithLocalTimeZone />
                    </ProtectedRoute>
                ),
                title: "Event Calendar List",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/activate_user_through_email",
                element: <ActivateUserThroughEmail />,
                title: "Activate User Through Email",
            }, //for useRoutes, we do not need 'title'
            { path: "/yup_demo", element: <YupDemo />, title: "Yup Demo" }, //for useRoutes, we do not need 'title'
            {
                path: "/search_filters",
                element: <SearchFilters />,
                title: "Search Filters",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/mui_component_01",
                element: <MUIComponent01 />,
                title: "MUI Component 01",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/mui_font_color",
                element: <MUIFontColorComponent />,
                title: "MUI Font Color",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/captcha",
                element: <InputCAPTCHA />,
                title: "Captcha Demo",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/pdfviewer/:id/:date",
                element: <PdfViewer />,
                title: "PDF Viewer",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/pdfviewer/:id",
                element: <PdfViewer />,
                title: "PDF Viewer",
            }, //for useRoutes, we do not need 'title'
            // we should add '/' after '/pdfviewer' below.
            {
                path: "/pdfviewer/",
                element: <PdfViewer />,
                title: "PDF Viewer",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/storeflyerviewer/",
                element: <StoreFlyerViewer />,
                title: "Store Flyer Viewer",
            },
            {
                path: "/scrolldemo",
                element: <ScrollDemo />,
                title: "Scroll Demo",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/springlightboxdemo",
                element: <SpringLightboxDemo />,
                title: "Spring Lightbox Demo",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/photoswipedemo",
                element: <PhotoSwipeDemo />,
                title: "photo Swipe Demo",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/muiswiipe",
                element: <MUIMultiCarouselWithPhotoSwipeGallery />,
                title: "MUIMultiCarouselWithPhotoSwipeGallery",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/pdfembed",
                element: <PDFEmbed />,
                title: "PDF embed Viewer",
            }, //for useRoutes, we do not need 'title'
            { path: "/printit", element: <PrintIt />, title: "Print it" }, //for useRoutes, we do not need 'title'
            {
                path: "/no_permission",
                element: <NoPermissionAllowed />,
                title: "No Permission allowed",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/stripe_payment",
                element: <StripeCheckoutForm />,
                title: "No Permission allowed",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/stripe_payment_success",
                element: <StripePaymentSuccess />,
                title: "Payment Success",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/stripe_payment_cancel",
                element: <StripePaymentCancel />,
                title: "Payment cancel",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/stripe_all_payments",
                element: <StripeListAllPayments />,
                title: "All Payment list",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/pdf_download_demo",
                element: <PDFDownloadDemo />,
                title: "PDF download demo",
            }, //for useRoutes, we do not need 'title'
            {
                path: "/i18ndemo",
                element: <I18nDemo />,
                title: "I18n demo",
            }, //for useRoutes, we do not need 'title'
            { path: "*", element: <W404 /> },
        ],
        [t]
    );
};

export default useComponents;
