import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import { Dropdown } from 'react-bootstrap';
import 'flag-icons/css/flag-icons.min.css';

const LanguageSelector = (): React.ReactElement => {
  const languages = [
      {
          code: 'en',
          name: 'English',
          country_code: 'gb',
      },
      {
        code: 'zh',
        name: '中文',
        country_code: 'cn',
    },
  ]
  const {t} = useTranslation();

  return (
    <div className="dropdown">

        <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
            Dropdown Button
        </Dropdown.Toggle>

        <Dropdown.Menu>
           {languages.map(({ code, name, country_code}, index) => (
                <Dropdown.Item key={index}>
                    <span className={`fi fi-${country_code}`}></span>
                  <button onClick={() => {i18next.changeLanguage(code)}} data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right">
                    {name}
                  </button>
                </Dropdown.Item>
              ))}
        </Dropdown.Menu>
        </Dropdown>


    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    {languages.map(({ code, name, country_code}, index) => (
                <li key={index}>
                    <span className={`fi fi-${country_code}`}></span>
                  <button onClick={() => {i18next.changeLanguage(code)}} data-bs-toggle="tooltip" data-bs-placement="right" title="Tooltip on right">
                    {name}
                  </button>
                </li>
              ))}
    </ul>
  </div>
  );
}

export default LanguageSelector;