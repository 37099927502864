import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "@react-redux/reducers/user";
import tokenReducer from "@react-redux/reducers/token";

import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    PersistConfig,
  } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' //for localStorage
import storage from 'redux-persist/lib/storage/session' //for sessionStorage

const rootReducer = combineReducers({
user: userReducer,
token: tokenReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistConfig: PersistConfig<RootState> = {
key: 'root',
version: 1,
storage,
whitelist: ['token'] //only token state is persisted; token is come from rootReducer 
}

const persistedRootReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
reducer: persistedRootReducer,
middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    }),
});


store.subscribe(()=>{
    console.log('store in store.ts', store.getState());
})

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;
  
